import howler from "js/lib/howler.min.js"
howler.unlockMobileAudio();


var u = navigator.userAgent, app = navigator.appVersion;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
const createAudio = (src) => {
    if(isIOS){
        let $audio = document.createElement('audio');
        $audio.src = src;
        $audio.loop = true;
        $audio.style.display = 'none';
        document.body.appendChild($audio);
        return $audio;
    }
    if(isAndroid){
        return new Howl({
            src: src,
            buffer: true,
            loop: true,
        });
    }
}


export class bgAudioFix {
    constructor(opt) {
        if(!opt || !opt.src){
            throw new Error('缺少音频src');
        }

        this.opt = opt;
        this._audio = createAudio(opt.src); //创建audio

        if(this.opt.autoPlay){
            this.#audioAutoPlay();
            this.#autoPlayMusic();
        }
    }
    #audioAutoPlay = () => {
        let _audio = this._audio
        _audio.play();
        document.addEventListener("WeixinJSBridgeReady", function () {
            _audio.play();
        }, false);
    }
    
       // 音乐播放
        #autoPlayMusic = () => {
            let that = this;
        // 自动播放音乐效果，解决浏览器或者APP自动播放问题
        function musicInBrowserHandler() {
            that.musicPlay(true);
            document.body.removeEventListener('touchstart', musicInBrowserHandler);
        }
        document.body.addEventListener('touchstart', musicInBrowserHandler);
        // 自动播放音乐效果，解决微信自动播放问题
        function musicInWeixinHandler() {
            that.musicPlay(true);
            document.addEventListener("WeixinJSBridgeReady", function () {
                that.musicPlay(true);
            }, false);
            document.removeEventListener('DOMContentLoaded', musicInWeixinHandler);
        }
        document.addEventListener('DOMContentLoaded', musicInWeixinHandler);
    }
    musicPlay(isPlay) {
        let media = this._audio;
        let playing = media.playing ? media.playing() : !media.paused;

        if ( isPlay && !playing ) {
            media.play();
        }
        if (!isPlay && playing) {
            media.pause();
        }
    }
}




//解锁屏幕滑动
export const screenScrollEnable = (el) => {
  let $el = el || document;
  $el.removeEventListener('touchmove', noScroll, {
    passive: false
  });
}
//锁定屏幕滑动
export const screenScrollUnable = (el) => {
  let $el = el || document;
  $el.addEventListener('touchmove', noScroll, {
    passive: false
  });
}

const noScroll = (e) => {
  e.preventDefault();
}
const setFontSize = () => {
  (function () {
    if (typeof window.WeixinJSBridge == "object" && typeof window.WeixinJSBridge.invoke == "function") {
      handleFontSize();
    } else {
      if (document.addEventListener) {
        document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", handleFontSize);
        document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
      }
    }
    function handleFontSize() {
      // 设置网页字体为默认大小
      window.WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
      // 重写设置网页字体大小的事件
      window.WeixinJSBridge.on('menu:setfont', function () {
        window.WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
      });
    }
  })();
}

export const isIOS = () => {
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}



export const setBlurScrollTo = () => {
  let goBack = null, flag = false;
  if (isIOS()) {
    document.body.addEventListener('focusin', () => {
      //软键盘弹起事件
      flag = true;
      clearTimeout(goBack);
    })
    document.body.addEventListener('focusout', () => {
      flag = false;
      if (!flag) {
        goBack = setTimeout(function () {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          //当键盘收起的时候让页面回到原始位置
          //(这里的top可以根据你们个人的需求改变，并不一定要回到页面顶部)
        }, 200);
      } else {
        return false;
      }
    })
  }
}

export const init = () => {
  let $el = document.getElementById('app') || null;
  // $el && ($el.style.height = window.innerHeight + 'px');
  screenScrollUnable($el);
  setFontSize();
  setBlurScrollTo();
}


//以帧为单位的setTimeout
export const setTimeout = (callback, frame) => {
  if (frame > 0 && callback) return setTimer(callback, frame, false);
}

export const clearTimeout = (timer) => {
  if (timer && timer.timer) clearTimer(timer);
} //edn func

//以帧为单位的setInterval
export const setInterval = (callback, frame) => {
  if (frame > 0 && callback) return setTimer(callback, frame, true);
} //edn func

export const clearInterval = (timer) => {
  if (timer && timer.timer) clearTimer(timer);
} //edn func

export const clearTimer = (timer) => {
  cancelAnimationFrame(timer.timer);
  timer = null;
}

function setTimer(callback, frame, interval) {
  var timer = {
    now: 0,
    timer: null
  };
  timer_handler();
  return timer;

  function timer_handler() {
    // console.log(timer.timer);
    timer.now++;
    var timeup = timer.now == frame;
    if (timeup) {
      timer.now = 0;
      callback();
    } //end if
    if (interval || (!interval && !timeup)) timer.timer = requestAnimationFrame(timer_handler);
  } //end func

} //edn func



export const GetQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]); return null;
}